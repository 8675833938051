import React from 'react'
import Helmet from 'react-helmet'
import Lightbox from 'react-image-lightbox'

import Hero from '../components/Hero/Hero'
import PurposeHero from '../components/PurposeHero/PurposeHero'
import SubHero from '../components/SubHero/SubHero'
import WirelessVR from '../components/WirelessVR/WirelessVR'
import EmailList from '../components/EmailList/EmailList'
import WithoutHeadset from '../components/WithoutHeadset/WithoutHeadset'
import HowItWorks from '../components/HowItWorks/HowItWorks'
import SocialLinks from '../components/SocialLinks/SocialLinks'
import Testimonials from '../components/Testimonials/Testimonials'
import OvationForEducation from '../components/OvationForEducation/OvationForEducation'
import { metaData } from '../meta'
import OvercomeYourFear from '../components/OvercomeYourFear/OvercomeYourFear'

const analyticsCarouselImages = [
  // 'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/QAndA_1.png',
  // 'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/Conversation_1.png',
  // 'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/Interview_1.png'
  'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/1.png',
  'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/2.png',
  'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/3.png',
  'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/4.png',
  'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/5.png',
  'https://d1syj4d8txnu77.cloudfront.net/AIPoweredSpeaking/6.png'
]

const aiPoweredFeedbackAnalyticsImages = [
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/1_ReviewMenu.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/2_ReportMenu_Details.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/3_ReportMenu_Transcript.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/4_ReportMenu_Factors.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/5_ReportMenu_Quotes.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/6_ReportMenu_Tips.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/7_ReportMenu_Analytics.jpg',
  'https://d1syj4d8txnu77.cloudfront.net/AIPowereFeedbackAnalytics/8_ReportMenu_Analytics_Fillers.jpg'
]

let scrolled25PercentYet, scrolled50PercentYet, scrolled75PercentYet, scrolled100PercentYet

class RootIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      photoIndex: 0,
      section: 0
    }
  }

  componentDidMount() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      js: new Date()
    })
    window.dataLayer.push({
      config: 'G-CKGW198D3H'
    })

    window.addEventListener('scroll', this.handleScroll, true)
    scrolled25PercentYet = false
    scrolled50PercentYet = false
    scrolled75PercentYet = false
    scrolled100PercentYet = false
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

    const scrolledPercentage = winScroll / height // 0 to 1

    window.dataLayer = window.dataLayer || []
    if (scrolledPercentage >= 0.25 && !scrolled25PercentYet) {
      window.dataLayer.push({
        event: 'gtm-behavior-home-page-scroll-depth-25'
      })
      scrolled25PercentYet = true
    } else if (scrolledPercentage >= 0.5 && !scrolled50PercentYet) {
      window.dataLayer.push({
        event: 'gtm-behavior-home-page-scroll-depth-50'
      })
      scrolled50PercentYet = true
    } else if (scrolledPercentage >= 0.75 && !scrolled75PercentYet) {
      window.dataLayer.push({
        event: 'gtm-behavior-home-page-scroll-depth-75'
      })
      scrolled75PercentYet = true
    } else if (scrolledPercentage >= 1 && !scrolled100PercentYet) {
      window.dataLayer.push({
        event: 'gtm-behavior-home-page-scroll-depth-100'
      })
      scrolled100PercentYet = true
    }
  }

  openLightboxWithImageIndex(index, section) {
    this.setState({
      isOpen: true,
      photoIndex: index,
      section: section
    })
  }

  renderLightbox(isOpen, photoIndex, section) {
    if (typeof window !== 'undefined') {
      return (
        <div>
          {isOpen &&
            (section === 0 ? (
              <Lightbox
                mainSrc={analyticsCarouselImages[photoIndex]}
                nextSrc={analyticsCarouselImages[(photoIndex + 1) % analyticsCarouselImages.length]}
                prevSrc={
                  analyticsCarouselImages[
                    (photoIndex + analyticsCarouselImages.length - 1) % analyticsCarouselImages.length
                  ]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + analyticsCarouselImages.length - 1) % analyticsCarouselImages.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % analyticsCarouselImages.length
                  })
                }
              />
            ) : (
              <Lightbox
                mainSrc={aiPoweredFeedbackAnalyticsImages[photoIndex]}
                nextSrc={aiPoweredFeedbackAnalyticsImages[(photoIndex + 1) % aiPoweredFeedbackAnalyticsImages.length]}
                prevSrc={
                  aiPoweredFeedbackAnalyticsImages[
                    (photoIndex + aiPoweredFeedbackAnalyticsImages.length - 1) % aiPoweredFeedbackAnalyticsImages.length
                  ]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + aiPoweredFeedbackAnalyticsImages.length - 1) %
                      aiPoweredFeedbackAnalyticsImages.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % aiPoweredFeedbackAnalyticsImages.length
                  })
                }
              />
            ))}
        </div>
      )
    }
  }

  render() {
    const { isOpen, photoIndex, section } = this.state
    return (
      <div style={{ background: '#fff' }}>
        <Helmet title={metaData.home.title}>
          <meta name="description" content={metaData.home.description} />
          <meta name="keywords" content={metaData.home.keywords} />
          <meta name="facebook-domain-verification" content="h9g3c9kneipzfgy0nygjyee59bs424" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-CKGW198D3H"></script>
        </Helmet>
        <Hero
          data={{
            cta: 'VR-enabled public speaking training'
          }}
        />
        <PurposeHero />
        <SubHero backgroundColor="white" />
        <HowItWorks
          openLightboxWithImageIndex={this.openLightboxWithImageIndex.bind(this)}
          analyticsCarouselImages={analyticsCarouselImages}
          aiPoweredFeedbackAnalyticsImages={aiPoweredFeedbackAnalyticsImages}
        />
        <WirelessVR />
        <WithoutHeadset />
        <Testimonials />
        <OvercomeYourFear />
        <OvationForEducation />
        <EmailList gtmSubmitEventName="gtm-newsletter-add-contact-home-page" backgroundColor="#fff" />
        <SocialLinks />
        {this.renderLightbox(isOpen, photoIndex, section)}
      </div>
    )
  }
}

export default RootIndex
